@import '../../styles/appColors.scss';

.home {
  &__header {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__title {
    font-size: 2.5rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
  }

  &__subtitle {
    font-size: 1.2rem;
    color: var(--text-color);
  }

  @media (max-width: 768px) {
    &__title {
      font-size: 2rem;
    }

    &__subtitle {
      font-size: 1rem;
    }
  }

  @media (max-width: 480px) {
    &__title {
      font-size: 1rem;
    }

    &__subtitle {
      font-size: 0.8rem;
    }
  }

}
