// Footer.scss

@import '../../styles/appColors.scss';

.footer {
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;  // White text color
  padding: 10px 20px;  // Adjust padding to match AppBar
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;  // Fixed position at the bottom
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;  // Ensure it's above other content
}

.footer-text {
  font-size: 0.9rem;
  font-weight: 500;  // Medium weight to match AppBar text
}