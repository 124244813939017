// Contact.scss
@import '../../styles/appColors.scss';



.contact {

    &__header {
        text-align: center;
        margin-bottom: 2rem;
      }
    
      &__title {
        font-size: 2.5rem;
        color: var(--text-color);
        margin-bottom: 1rem;
      }
    
      &__subtitle {
        font-size: 1.2rem;
        color: var(--text-color);
        margin-bottom: 1rem;
      }

      &__content {
        font-size: 1rem;
      }

      .MuiOutlinedInput-root {
        fieldset {
          border-color: var(--text-color);
          opacity: 0.23;
        }
        &:hover fieldset {
          border-color: var(--text-color);
          opacity: 0.5;
        }
        &.Mui-focused fieldset {
          border-color: var(--text-color);
          opacity: 0.5;
        }
      }
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 100px var(--background-color) inset;
        -webkit-text-fill-color: var(--text-color);
      }
      .MuiInputLabel-root {
        color: var(--text-color);
        opacity: 0.7;
        &.Mui-focused {
          color: var(--text-color);
          opacity: 0.7;
        }
      }
      .MuiOutlinedInput-input {
        color: var(--text-color);
        opacity: 0.7;
      }
      .MuiFormHelperText-root {
        color: var(--text-color);
        opacity: 0.7;
      }

      .contact__main__btn {
        background-color: var(--primary-color);
        color: var(--text-color);
      }
    
      @media (max-width: 768px) {
        &__title {
          font-size: 2rem;
        }
    
        &__subtitle {
          font-size: 1rem;
        }

        &__content {
            font-size: 0.8rem;
        }
      }
    
      @media (max-width: 480px) {
        &__title {
          font-size: 1rem;
        }
    
        &__subtitle {
          font-size: 0.8rem;
        }

        &__content {
            font-size: 0.6rem;
        }
      }
}
