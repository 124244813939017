// Settings.scss
@import '../../styles/appColors.scss';

.settings-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.settings-item {
    display: flex;
    justify-content: space-between;

    .MuiListItemIcon-root {
        color: var(--text-color);
    }

    .MuiListItemText-root {
        color: var(--text-color);
    }

    .MuiSwitch-root {
        color: var(--text-color);
    }

    .MuiFormControl-root {
        color: var(--text-color);
    }

    .MuiFormControlLabel-root {
        color: var(--text-color);
    }

    .MuiSelect-select {
        color: var(--text-color);
    }

    .MuiInputBase-root {
        color: var(--background-color);
        // background-color: var(--text-color);
    }

    .MuiInputLabel-root {
        color: var(--text-color);
    }
    
}

.theme-switch {
    margin-left: 1rem;
}

.currency-select {
    margin-left: 1rem;
}

.language-select {
    margin-left: 1rem;
}

.timezone-select {
    margin-left: 1rem;
}


