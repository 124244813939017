body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh !important;
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  overflow: auto;
  /* padding-bottom: 100px; */

  main {
    flex: 1;
    padding: 5px 15px 50px 15px;
    /* 5px top, 50px bottom, 5px left, 5px right */
  }

  .MuiListItemIcon-root {
    color: var(--text-color);
  }

  .MuiListItemText-root {
    color: var(--text-color);
  }

  .MuiSwitch-root {
    color: var(--text-color);
  }

  .MuiFormControl-root {
    color: var(--text-color);
  }

  .MuiFormControlLabel-root {
    color: var(--text-color);
  }

  .MuiSelect-select {
    color: var(--text-color);
  }

  .MuiInputBase-root {
    color: var(--background-color);
    /* background-color: var(--text-color); */
  }

  .MuiInputLabel-root {
    color: var(--text-color);
  }
}