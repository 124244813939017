// Drawer.scss

@import '../../styles/appColors.scss';

.drawer {
  background-color: transparent;
  .MuiDrawer-paper {
    background-color: var(--background-color);
    color: var(--text-color);
  }

  .drawer__list {
    .MuiListItemIcon-root {
        color: var(--text-color);
    }
  }
}