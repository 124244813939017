// appColors.scss
$light-background: #ffffff;
$light-primary: #b19cd9;
$light-secondary: #d6c6e1;
$light-text: #333333;
$dark-background: #1a1a1a;
$dark-primary: #8e7cc3;
$dark-secondary: #6b5b95;
$dark-text: #f0f0f0;

:root {
  --background-color: #{$light-background};
  --text-color: #{$light-text};
  --primary-color: #{$light-primary};
  --secondary-color: #{$light-secondary};
}

[data-theme='dark'] {
  --background-color: #{$dark-background};
  --text-color: #{$dark-text};
  --primary-color: #{$dark-primary};
  --secondary-color: #{$dark-secondary};
}
