@import '../../styles/appColors.scss';

.under-construction-container {
  position: relative;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--background-color);
  border: 2px solid var(--text-color);
  margin: 20px;
}

.tape-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}

.tape {
  position: absolute;
  width: 100%;
  height: 20px;
  background: repeating-linear-gradient(45deg, black 0%, black 25%, yellow 25%, yellow 50%);
}

.tape-top {
  top: 20px;
  transform: rotate(10deg);
}

.tape-bottom {
  bottom: 20px;
  transform: rotate(-10deg);
}

.text-container {
  z-index: 1;
  text-align: center;
}

.main-text {
  font-size: 48px;
  color: var(--text-color);
  margin: 0;
}

.sub-text {
  font-size: 24px;
  color: var(--text-color);
  margin: 0;
}

@media (max-width: 768px) {
  .main-text {
    font-size: 36px;
  }

  .sub-text {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .main-text {
    font-size: 24px;
  }

  .sub-text {
    font-size: 12px;
  }
}
